import React from 'react';
import withUsabilityTestingTemplate from 'hoc/content-cluster';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Link from 'components/ui/link';
import { transformImages } from 'helpers/transformImages';
import arrow from 'img/ui/arrow_white.svg';

const UXProtptyping = () => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    query UXProtptyping {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "user-experience/ux-prototyping" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(quality: 95) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  const images = transformImages(edges);
  return (
    <>
      <h2>UX Prototyping – What Is It Exactly</h2>
      <p>
        Before venturing into UX prototyping, it is worth explaining what a prototype is and what
        business benefits it brings. A prototype is an elementary design that has the potential to
        turn into the final product. It is a basic version of a product that enables you to conduct
        initial product testing and collect feedback from potential users.
      </p>
      <p>Now, let us investigate its various benefits. </p>
      <h3>What are the benefits of UX prototyping?</h3>
      <p>
        UX{' '}
        <Link
          href="https://ux247.com/the-benefits-of-prototyping/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          prototyping
        </Link>{' '}
        provides a plethora of benefits and can be conducted for various reasons:
      </p>
      <ul>
        <li>
          <strong>Verifying product-market fit</strong>: Prototyping gives us tools and resources
          for testing and validating our ideas and making sure they are fit for the market. If the
          product is not deemed market worthy after the prototyping results, you can always adjust
          and add refinements to bring it up to par. The useful insights that prototyping provides
          always goes a long way in turning your ideas into reality.{' '}
        </li>
        <li>
          <strong>Improving user experience</strong>: Prototyping is a vital tool for a UX designer
          and helps in elevating the{' '}
          <Link href="/user-experience/" target="_blank" rel="noopener noreferrer">
            user experience
          </Link>{' '}
          as well. It makes the job of a designer easy by gathering important feedback and insights,
          thus improving the user experience as an end-result. The ability to produce rapid UX
          prototyping and quick design revisions means a better product that is achieved in a
          shorter time with less cost and effort put into development.{' '}
        </li>
        <li>
          <strong>Shortening development time and costs</strong>: Research shows that prototyping
          shortens development time and costs. Prototyping reveals bugs ahead of time and lets you
          fix them before you start working on UI tasks. If you omit the prototyping stage, then you
          risk creating a less efficient UX design that might eventually need redesigning. Testing
          early and prototyping is much easier than redesigning the entire UI.{' '}
        </li>
      </ul>
      <Img fluid={images.meeting.node.childImageSharp.fluid} title="Meeting" alt="Prototyping" />

      <p>
        Source:{' '}
        <Link
          href="https://unsplash.com/photos/Oalh2MojUuk"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Unsplash
        </Link>
      </p>
      <p>
        Now that we know the benefits of prototyping, let us discuss the different prototyping
        methodologies available.
      </p>
      <h2>Prototyping methodologies </h2>

      <p>
        Prototyping is not a monolith - there are various methodologies, which can be used in UX
        prototyping. Depending on the types of ideas you are trying to serve, or the types of
        problems that need to be solved, here are a few major ones that should be incorporated into
        your UX process:
      </p>
      <h3>1. Paper prototyping</h3>
      <p>
        <Link
          href="https://www.justinmind.com/blog/the-beginners-guide-to-prototypes/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Paper prototyping
        </Link>{' '}
        is popular among UX designers due to its inexpensive and flexible nature. Some people
        confuse sketching with paper prototyping, but they are both quite different. Paper
        prototypes are more versatile than regular sketches and can be created by using materials
        like cardboard and stencils to help produce more defined screens. It can be cut out of paper
        which gives you the flexibility of moving and switching UI elements around. You can also
        incorporate post-it-notes into your paper prototype to start defining your application flow.
        Here are a few major benefits of using this prototype:
      </p>

      <p>
        <strong>No special skills required</strong>: Even a novice UX designer can create paper
        prototypes as they are simply made of paper. No special skills are required but a great
        imagination goes a long way.{' '}
      </p>

      <p>
        <strong>They are inexpensive</strong>: Paper is very inexpensive and since these prototypes
        are made from paper, they are very budget-friendly as well.
      </p>

      <p>
        <strong>Low fidelity</strong>: Paper prototypes are low fidelity – or so-called limited
        function prototypes, but that does not make them any less valuable. These wireframes are
        normally concerned with what goes where in a design rather than how it looks or the colors
        that will be chosen. Due to this reason, they are faster to create than their high-fidelity
        counterparts and aid in expediting the UX design process.
      </p>
      <Img
        fluid={images.analyze.node.childImageSharp.fluid}
        title="Paper prototype"
        alt="Prototyping on paper"
      />

      <p>
        Source:{' '}
        <Link
          href="https://unsplash.com/photos/sv8oOQaUb-o"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Unsplash{' '}
        </Link>
      </p>
      <h3>2. Digital prototyping</h3>
      <p>
        Digital prototypes are interactive and quite robust and are mainly used for app prototyping.
        You can see the functionality, usability, and realism all come together with these
        prototypes. Here are the key features:
      </p>

      <p>
        <strong>They are realistic</strong>: They are clickable and respond to actions, therefore,
        they provide a complete and realistic visual picture of your mobile app or website to the
        stakeholders before it goes into production.
      </p>

      <p>
        <strong>Can reduce development time</strong>: Studies have shown over time that digital
        prototyping allows developers to catch design problems upfront which reduces development
        time. Early digital simulations in the product development cycle can expedite the
        development process as most digital prototyping tools have drag and drop interfaces and no
        coding is required.
      </p>

      <p>
        <strong>A useful reference source for developers</strong>: Digital prototypes can be used to
        understand the functionality of a product in a way that paper or screen specifications
        cannot deliver. As an example, if a developer needs to understand how a specific widget or
        control should work, they can use the digital prototype to easily answer functionality
        questions.
      </p>
      <Img
        fluid={images.prototype.node.childImageSharp.fluid}
        title="Prototype on computer"
        alt="Developer prototyping"
      />

      <p>
        Source:{' '}
        <Link
          href="https://www.a4plus.co.uk/prototyping-manufacture/digital-prototyping/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          a4plus
        </Link>
      </p>
      <h3>3. HTML prototyping</h3>

      <p>
        <Link
          href="https://www.justinmind.com/blog/the-beginners-guide-to-prototypes/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          HTML prototypes
        </Link>{' '}
        are getting obsolete, but they still have a secure place in the world of UX design. As the
        name implies, this prototype is built by using the HTML language. It offers a great
        advantage to people who are fluent in coding. They are simplistic in design without any
        fancy typography, imagery, or color choices and can be useful for website prototyping. Here
        are a few benefits of HTML prototypes:
      </p>

      <p>
        <strong>Can be viewed in any browser</strong>: All browsers support HTML. Since this
        prototype is coded in HTML, it can be viewed in any web browser.
      </p>

      <p>
        <strong>Inexpensive to create</strong>: You do not need any special tools to code a
        prototype in HTML. There are many free HTML editing tools that you can download free of
        cost. This makes this prototype very cost-effective as well.
      </p>
      <p>
        <strong>Sets the technical foundation</strong>: HTML prototypes set the foundation for a
        product and that foundation can be iterated upon. HTML prototypes also go into the coding
        stage faster since they have already been partly written in code.{' '}
      </p>

      <p>
        In the next section, we are going to glance into the steps required before creating a
        prototype.
      </p>
      <Img fluid={images.code.node.childImageSharp.fluid} title="Prototype in HTML" alt="code" />
      <p>
        Source:{' '}
        <Link
          href="https://unsplash.com/photos/u2Ru4QBXA5Q"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Unsplash
        </Link>{' '}
      </p>

      <h2>How to Approach UX Prototyping </h2>
      <p>
        Irrespective of whether you are building an app or a website prototype, there are several
        steps you need to take before creating one:
      </p>

      <h3>Step 1: Develop and perfect your idea </h3>
      <p>
        The first step before bringing your idea to life is to{' '}
        <Link href="/user-experience/ux-research/">
          figure out what problem it is going to solve
        </Link>
        . For example, if you are trying to build a mobile app that lets users create a to-do-list,
        you need to ensure your app is unique in terms of solving the problems of your target
        audience. This idea might seem basic at best, but most successful apps always pay attention
        to this step before moving forward with the prototype and design phases. To you, your idea
        might seem revolutionary, but for the end-user, it must offer real functionality as they
        will be downloading, sharing, and using your app regularly. Questions like{' '}
        <em>“how different is your app compared to similar ones?”</em>,{' '}
        <em>“does it offer extra functionalities or integrations with other apps?”</em> or{' '}
        <em>“does it provide an appealing interface?”</em> should be addressed beforehand.
      </p>
      <h3>Step 2: Come up with product requirements</h3>
      <p>
        Once you have identified the problems your product or app will solve; it is time to create a
        list of the cover features and prioritize them. Start by creating a list of main features
        your app should have and then move on to shortlisting the ones that will form the core of
        your app. The prioritization of features should be addressed next and should align with the
        needs and preferences of your target audience. It would be best if you divide the features
        into “must-haves” and “nice-to-haves”. Take the first 3-4 features on the priority list and
        begin crafting your prototype with them in mind.
      </p>

      <h3>Step 3: Draw sketches of your app or web primary screens</h3>
      <p>
        This step is all about the user experience. To keep the features of your app visually
        organized, start by sketching the primary screens of your app. Once the sketches are ready,
        you can start adding details and keep building the screens further. The initial flow should
        already consist of one potential user flow that you can keep on refining as you venture into
        developing your app.
      </p>
      <h3>Step 4: Build wireframes based on your sketches</h3>

      <p>
        Wireframing is the next step in developing your prototype. A wireframe is a basic outline of
        your product and is normally based on elements like boxes, words, and lines. It is wise to
        add a few descriptions in those elements to communicate your concept better. The idea behind
        wireframing is to not create the final look for your app but to create a framework that sets
        your app in the right direction.
      </p>
      <h3>Step 5: Build a prototype</h3>
      <p>
        Now it is time to employ your wireframes to create a prototype. You can either put them
        together to create a basic prototype or help transform the initial ideas into interactive
        prototypes by using specialized tools. These tools build prototypes that have the look and
        feel of real apps by using fake content.{' '}
      </p>
      <h2>Summary</h2>
      <p>
        Prototypes are the essence of any design project. They bring immense advantages to the
        entire UX process – from the ideation phase to the completion phase. In this article, we
        provided a comprehensive insight into the types of prototypes, their benefits, and the types
        of methodologies used with them. We discussed that a prototype is an elementary draft that
        provides many benefits of verifying the product-market fit, improved user experience, and
        shortened development times and costs.{' '}
      </p>
      <p>
        We also glanced over the main types of prototyping methodologies that include paper,
        digital, and HTML prototyping. Lastly, we discussed the various steps involved in the
        creation of a prototype: a) develop and perfect your idea b) come up with product
        requirements c) draw sketches of your app or web primary screens d) build wireframes based
        on your sketches e) build a prototype.
      </p>
      <div className="next-chapter">
        <Link href="/user-experience/ux-research/">
          <div>
            <p>Chapter 6</p>
            <p>How to Conduct Effective UX Research?</p>
          </div>
          <img src={arrow} alt="arrow" title="white arrow" />
        </Link>
      </div>
    </>
  );
};

export default withUsabilityTestingTemplate(UXProtptyping);
